<template>
<div>
    <b-row>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar fa-md"></i> <span class="h5"> Estadísticas </span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA PARA LAS NO CONFORMIDADES:
                    </span>
                    <hr>
                    <CChartPie :options="optionsEstadistica" class="my-2" :datasets="dataSetsEstadistica" :labels="labelEstadistica" />
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog fa-md"></i><span class="h5"> Gestión de No Conformidades</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="text-center justify-content-center">
                        <b-col v-if="checkPermissions('011-INC-NOC','c')==1"  cols="6" class="my-2">
                            <b-button block size="md" variant="custom" :to="{name: 'Gestion de no Conformidades'}">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br>Nueva no conformidad
                            </b-button>
                        </b-col>
                        <b-col cols="6" class="my-2">
                            <b-button block size="md" variant="custom" :to="{name: 'Informe Global no conformidades'}">
                                <i style="inline-size: auto" class="fas fa-chart-pie fa-3x pb-1"></i><br>Informe global
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i><span class="h5"> No Conformidades registradas </span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col md="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col md="7">
                        </b-col>
                        <b-col md="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                    <!--     <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>-->
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table hover show-empty mediun responsive outlined :items="listaNoConformidades" :fields="campoNoConformidades" :current-page="paginaActual" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template v-slot:cell(norma)="data">
                                    <span>{{templateNormas.filter(x => x.idNorma == data.value)[0].descripcion}}</span>
                                </template>

                                <template v-slot:cell(isResuelta)="data">
                                    <b-icon variant="success" v-if="data.value==2" icon="check-circle-fill"></b-icon>
                                    <b-icon variant="danger" v-else icon="x-circle-fill"></b-icon>
                                </template>
                                <template v-slot:cell(opciones)="param">
                                    <b-button  v-if="checkPermissions('011-INC-NOC','u')==1"   size="sm" v-c-tooltip.hover.click="'Ver detalles'" :to="{name: 'Gestion de no Conformidades', params: {id: param.item.idNoConformidad}}" variant="dark" class=" mr-1 mb-1">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-search my-0 mx-0"></i>
                                        </span>
                                    </b-button>
                                    <b-button  v-if="checkPermissions('011-INC-NOC','d')==1"  size="sm" class="mr-1 mb-1" @click="eliminarNoConformidad(param)" v-c-tooltip.hover.click="'Eliminar'" variant="danger">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                        </span>
                                    </b-button>
                                    <b-button size="sm" class="mr-1 mb-1" :disabled="disabled" variant="blue" @click="generarReporte(param)" v-c-tooltip.hover.click="'Descargar'">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-download my-0 mx-0"></i>
                                        </span>
                                    </b-button>
                                    <!-- <b-button  v-if="checkPermissions('011-INC-NOC','c')==1"  size="sm" class="mr-1 mb-1" variant="info" v-c-tooltip.hover.click="'Crear NC'">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-thumbs-down my-0 mx-0"></i>
                                        </span>
                                    </b-button> -->
                                </template>
                                <template #cell(nombre)="param">
                                    {{param.item.nombre | truncate(35)}}
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination size="sm" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number align="right" class="my-0"></b-pagination>
                </CCardfooter>
            </CCard>
        </b-col>
    </b-row>
</div>
</template>

<script>
import {
    CChartPie
} from '@coreui/vue-chartjs'
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

import Docxtemplater from "docxtemplater";
import {
    saveAs
} from "file-saver";
import ImageModule from "docxtemplater-image-module-free";
import JSZipUtils from "jszip-utils";
import JSZip from "jszip";

import expressions from 'angular-expressions';
import assign from "lodash.assign";

expressions.filters.lower = function (input) {
    // This condition should be used to make sure that if your input is
    // undefined, your output will be undefined as well and will not
    // throw an error
    if (!input) return input;
    return input.toLowerCase();
}

function angularParser(tag) {
    if (tag === '.') {
        return {
            get: function (s) {
                return s;
            }
        };
    }
    const expr = expressions.compile(
        tag.replace(/(’|‘)/g, "'").replace(/(“|”)/g, '"')
    );
    return {
        get: function (scope, context) {
            let obj = {};
            const scopeList = context.scopeList;
            const num = context.num;
            for (let i = 0, len = num + 1; i < len; i++) {
                obj = assign(obj, scopeList[i]);
            }
            return expr(scope, obj);
        }
    };
}

export default {
    components: {
        CChartPie
    },
    data() {
        return {
            disabled: false,
            labelEstadistica: ["Pendientes", "Resueltas"],
            optionsEstadistica: {
                legend: {
                    labels: {
                        fontSize: 15,
                    },
                    position: 'left',
                    align: 'center'
                },
            },
            filasTotales: 1,
            paginaActual: 1,
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],

            filter: null,
            filterOn: [],
            datosSession: {
                idCliente: ''
            },
            listaNoConformidades: [],
            campoNoConformidades: [{
                key: "index",
                label: "N°",
                class: "text-center",
            }, {
                key: "norma",
                label: "Norma",
                class: "text-center",
            }, {
                key: "fecha",
                label: "Fecha",
                class: "text-center",
            }, {
                key: "categoria",
                label: "Categoría",
                class: "text-center",
            }, {
                key: "nombre",
                label: "Proceso afectado",
                class: "text-center",
            }, {
                key: "isResuelta",
                label: "¿Resuelta?",
                class: "text-center",
            }, {
                key: "opciones",
                label: "",
                class: "text-center",
            }],
            templateCategorias: [{
                idCategoria: 1,
                descripcion: 'Denuncias Internas de soborno'
            }, {
                idCategoria: 2,
                descripcion: 'Documentación'
            }, {
                idCategoria: 3,
                descripcion: 'Documental'
            }, {
                idCategoria: 4,
                descripcion: 'Falta de Información'
            }, {
                idCategoria: 5,
                descripcion: 'Plazos'
            }],

            templateNormas: [{
                    idNorma: 1,
                    descripcion: 'Calidad (9001)'
                },
                {
                    idNorma: 2,
                    descripcion: 'MedioAmbiente (14001)'
                },
                {
                    idNorma: 3,
                    descripcion: 'Seguridad Laboral (45001)'
                },
                {
                    idNorma: 4,
                    descripcion: 'AntiSoborno (37001)'
                }
            ]
        }
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        base64DataURLToArrayBuffer(dataURL) {
            const base64Regex = /^data:image\/(png|jpg|svg|svg\+xml);base64,/;
            if (!base64Regex.test(dataURL)) {
                return false;
            }
            const stringBase64 = dataURL.replace(base64Regex, "");
            let binaryString;
            if (typeof window !== "undefined") {
                binaryString = window.atob(stringBase64);
            } else {
                binaryString = new Buffer(stringBase64, "base64").toString("binary");
            }
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                const ascii = binaryString.charCodeAt(i);
                bytes[i] = ascii;
            }
            return bytes.buffer;
        },
        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length
            this.paginaActual = 1
        },
        listarNoConformidades() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-no-conformidades", {
                    params: {
                        idCliente: me.datosSession.idCliente
                    }
                })
                .then(function (response) {
                    me.listaNoConformidades = response.data;
                    me.filasTotales = me.listaNoConformidades.length;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        eliminarNoConformidad(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar la No Conformidad?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    let me = this;
                    axios
                        .post(
                            CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-no-conformidad", {
                                idNoConformidad: param.item.idNoConformidad
                            }, {
                                headers: {
                                    Authorization: `Bearer ${localStorage.token}`
                                },
                            }
                        )
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarNoConformidades();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        generarReporte(param) {
            let me = this;
            //me.swat('info', 'Generando reporte espere porfavor ...')
            me.disabled = true;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/generar-reporte-no-conformidad", {
                        params: {
                            idCliente: param.item.idCliente,
                            idNoConformidad: param.item.idNoConformidad,
                            logo: JSON.parse(localStorage.settings).logo,
                        }
                        /* nombreArchivo: `No Conformidad`,
                        nombreLogo: "im-ra",
                        nombrePlantilla: "plantilla-no-conformidad",
                        idCliente: param.item.idCliente,
                        folder: "pdf/no-conformidad",

                        norma: me.templateNormas.filter(x => x.idNorma == param.item.norma)[0].descripcion,
                        categoria: me.templateCategorias.filter(x => x.idCategoria == param.item.norma)[0].descripcion,
                        proceso: param.item.nombre,
                        fecha: param.item.fecha,
                        fechaResolucion: param.item.fechaResolucion,
                        numero: param.item.numero,
                        personaDetecta: param.item.personaDetecta,
                        observacion: param.item.observacion,
                        hasConformidad: param.item.hasConformidad,
                        numeroVinculado: '0', */
                    }, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }
                )
                .then(function (response) {
                    //console.log(response.data)
                    // me.listarNoConformidades();
                    JSZipUtils.getBinaryContent(`${CONSTANTES.URL_RUTA_FRONTEND}plantillas/plantilla-no-conformidad.docx`, function (error, content) {
                        if (error) {
                            console.error(error);
                            return;
                        }
                        var opts = {}
                        opts.centered = true;
                        const imageOpts = {
                            getImage(tag) {
                                return me.base64DataURLToArrayBuffer(tag);
                            },
                            getSize() {
                                return [150, 60];
                            },
                        };

                        var imageModule = new ImageModule(imageOpts);

                        var zip = new JSZip(content);
                        var doc = new Docxtemplater()
                            .loadZip(zip)
                            .setOptions({
                                parser: angularParser
                            })
                            .attachModule(imageModule);
                        doc.setData({
                            //image: response.data.datosAsistentes[0].urlFirma,
                            logo: response.data.logo,
                            fecha: param.item.fecha,
                            numero: param.item.numero,
                            norma: me.templateNormas.filter(x => x.idNorma == param.item.norma)[0].descripcion,
                            categoria: param.item.categoria,
                            proceso: param.item.nombre,
                            responsable: param.item.responsable,
                            emailResponsable: param.item.emailResponsable,
                            descripcion: param.item.descripcion,
                            actuacionInmediata: param.item.actuacionInmediata,
                            posiblesCausas: param.item.posibleCausa,
                            accionCorrectiva: param.item.accionCorrectiva,
                            observacion: param.item.observacion,
                            responsableImplantacion: param.item.responsableImplantacion,
                            emailImplantacion: param.item.emailResponsableImplantacion,
                            fechaPrevista: param.item.fechaPrevista,
                            resuelta: param.item.isResuelta == '1' ? 'No' : 'Si',
                            fechaResolucion: param.item.fechaResolucion=='0000-00-00' ? '-' : param.item.fechaResolucion,
                            responsableImplantacion: param.item.responsableImplantacion,
                            personaDetecta: param.item.personaDetecta,
                            hasNoConformidad: response.data.data[0].numeroNCV!=null ? 'Si' : 'No',
                            numeroVinculado: response.data.data[0].numeroNCV!=null ? response.data.data[0].numeroNCV : '-',
                            // numeroNoConformidad: 'x',
                        });

                        try {
                            doc.render();
                        } catch (error) {
                            let e = {
                                message: error.message,
                                name: error.name,
                                stack: error.stack,
                                properties: error.properties
                            };
                            console.log(e)
                            throw error;
                        }
                        var out = doc.getZip().generate({
                            type: "blob",
                            mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                        });
                        saveAs(out, "no-conformidades.docx");
                    });
                    me.disabled = false;
                })
                .catch(function (error) {
                    console.log(error)
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },

        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    computed: {
        dataSetsEstadistica() {
            return [{
                borderWidth: 3,
                backgroundColor: [
                    '#f16553d4',
                    '#6bba70db',
                ],
                data: [this.listaNoConformidades.filter(x => x.isResuelta == 1).length, this.listaNoConformidades.filter(x => x.isResuelta == 2).length]
            }]
        }
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosSession.idCliente = user.uidClient;
            this.listarNoConformidades();
        }
    }

}
</script>
